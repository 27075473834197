import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { hasShowSubRoutes } from '@fingo/lib/helpers/routes';
import FundsDownloadTabs from './routes/FundsDownloadTabs';
import FundsDownloadManager from './components/FundsDownloadManager';

const fundsRoutes = (user) => [
  {
    id: 'funds-download',
    label: 'Nóminas de Descarga',
    path: 'rosters',
    icon: <DownloadIcon />,
    component: <FundsDownloadManager />,
    disabled: false,
    show: hasShowSubRoutes(user, FundsDownloadTabs(user)),
  },
];

export default fundsRoutes;
