import React from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';

const CertificateDownloadCell = ({ assignment }) => {
  const [fetchCertificate] = useFetchPresignedUrl(assignment?.globalAppId);
  return (
    <Stack width="100%" direction="column" alignItems="center">
      <IconButton
        color="primary"
        disabled={!assignment || assignment?.status !== 'COMPLETED'}
        onClick={fetchCertificate}
      >
        <AttachFileIcon />
      </IconButton>
    </Stack>
  );
};

CertificateDownloadCell.propTypes = {
  assignment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    globalAppId: PropTypes.string.isRequired,
  }),
};

CertificateDownloadCell.defaultProps = {
  assignment: null,
};

export default CertificateDownloadCell;
