import React from 'react';
import AddPayrollDialog from './AddPayrollDialog';
import SendRosterInvoicesToMoneyTransferDialog from './SendRosterInvoicesToMoneyTransferDialog';
import RequestInvoiceRecesionDialog from './RequestInvoiceRecesionDialog';

const FundsDownloadActions = () => (
  <>
    <RequestInvoiceRecesionDialog />
    <SendRosterInvoicesToMoneyTransferDialog />
    <AddPayrollDialog />
  </>
);

export default FundsDownloadActions;
