import React, { useState, useCallback } from 'react';
import {
  Button,
  DialogContent,
  MenuItem,
  Select,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import Publish from '@mui/icons-material/Publish';
import makeStyles from '@mui/styles/makeStyles';
import { GET_ROSTERS, GET_FUNDS, UPLOAD_ROSTER_FILE } from '@fingo/lib/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

const AddPayrollDialog = () => {
  const { addAlert } = useSnackBars();
  const classes = useStyles();
  const [open, toggleOpen] = useBooleanState();
  const { data, loading: loadingFunds } = useQuery(GET_FUNDS);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [selectedFund, setSelectedFund] = useState('isFund-select');
  const [selectedCessionare, setSelectedCessionare] = useState('isAsignee-select');
  const funds = data?.getFunds ?? [];
  const selectFund = useCallback((event) => {
    setSelectedFund(event.target.value);
  }, []);
  const selectCessionare = useCallback((event) => {
    setSelectedCessionare(event.target.value);
  }, []);
  const handleClose = useCallback(() => {
    setSelectedFund('isFund-select');
    setSelectedCessionare('isAsignee-select');
    setUploadDocument(null);
    toggleOpen();
  }, []);
  const fundsMapper = [
    {
      filter: 'isFund',
      label: 'fondo',
      selected: selectedFund,
      setSelected: selectFund,
    },
    {
      filter: 'isAsignee',
      label: 'cesionario',
      selected: selectedCessionare,
      setSelected: selectCessionare,
    },
  ];
  const [UploadRosterFileMutation, { loading: loadingUpload }] = useMutation(UPLOAD_ROSTER_FILE, {
    variables: {
      file: uploadDocument,
      fundId: selectedFund,
      cessionareId: selectedCessionare,
    },
    refetchQueries: [{ query: GET_ROSTERS }],
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'upload-roster-error',
      severity: 'error',
      message: `Error al subir la nómina: ${err}`,
    }),
  });
  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        onClick={toggleOpen}
        loading={loadingFunds}
      >
        Subir Nómina
      </LoadingButton>
      <FingoDialog
        open={open && !loadingFunds}
        handleClose={handleClose}
        title="Nueva Nómina"
        dialogActionButton={(
          <Stack direction="row" spacing={1}>
            <label htmlFor="contained-button-roster-file">
              <input
                id="contained-button-roster-file"
                type="file"
                onChange={(e) => { setUploadDocument(e.target.files[0]); }}
                className={classes.input}
              />
              <Button
                sx={{ height: '100%' }}
                size="small"
                component="span"
                color="primary"
                variant="contained"
                startIcon={<Publish />}
              >
                <Typography noWrap>{(uploadDocument && uploadDocument.name) || 'Subir documento'}</Typography>
              </Button>
            </label>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              disabled={
                uploadDocument === null
                || selectedFund === 'isFund-select'
                || selectedCessionare === 'isAsignee-select'
              }
              onClick={UploadRosterFileMutation}
              loading={loadingUpload}
            >
              Subir
            </LoadingButton>
          </Stack>
      )}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Stack direction="column" spacing={0.8}>
              <Typography variant="h2">
                Selecciona el archivo .xlsx con los campos:
              </Typography>
              <Box sx={{ fontFamily: 'Monospace' }}>invoice_id</Box>
              <Box sx={{ fontFamily: 'Monospace' }}>monthly_rate (opcional. e.g.: 1.43)</Box>
              <Box sx={{ fontFamily: 'Monospace' }}>expiration_date (opcional. e.g.: 2023-05-01)</Box>
              <Typography variant="h2">Las facturas tienen que estar con estado TRANSFERED</Typography>
            </Stack>
            <Stack spacing={2} direction="row">
              {fundsMapper.map((fundType) => (
                <Stack justifyContent="center" key={`stack-${fundType.filter}`} spacing={1}>
                  <Typography
                    sx={{ marginTop: 1 }}
                    variant="h6"
                    align="center"
                    key={`typography-${fundType.filter}`}
                  >Seleccione un {fundType.label}
                  </Typography>
                  <Select
                    value={fundType.selected}
                    variant="outlined"
                    onChange={fundType.setSelected}
                    sx={{ width: 200 }}
                    key={`select-${fundType.filter}`}
                  >
                    <MenuItem key="mi-select" value={`${fundType.filter}-select`} disabled>Seleccione un fondo</MenuItem>
                    {funds.filter((fund) => fund[fundType.filter]).map((option) => (
                      <MenuItem
                        key={`${fundType.filter}-${option.id}`}
                        value={option.id}
                      >
                        {option.masterEntity.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </DialogContent>
      </FingoDialog>
    </>
  );
};

export default AddPayrollDialog;
