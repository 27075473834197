import React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { formatRut } from '@fingo/lib/helpers';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMutation } from '@apollo/client';
import { GET_ROSTERS, ROSTER_RECESION } from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import { MoneyType } from '@fingo/lib/propTypes';

const ConfirmRecesionDialog = ({ roster }) => {
  const { addAlert } = useSnackBars();
  const [open, toggleOpen] = useBooleanState();
  const [RosterRecesionMutation, { loadingRecesion }] = useMutation(ROSTER_RECESION, {
    variables: { rosterId: roster.id },
    refetchQueries: [{ query: GET_ROSTERS }],
    onCompleted: () => {
      toggleOpen(false);
    },
    onError: (err) => addAlert({
      id: 'recesion-error',
      severity: 'error',
      message: `Error al confirmar la recesión: ${err}`,
    }),
  });
  return (
    <>
      <Button
        onClick={toggleOpen}
        disabled={roster.recesionDate !== null}
      >
        Receder
      </Button>
      <FingoDialog
        title={`Confirmar recesión de nómina a ${roster.fund.masterEntity.name}`}
        open={open}
        handleClose={toggleOpen}
        maxWidth="lg"
        dialogActionButton={(
          <>
            <Button>
              Ver nómina
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={RosterRecesionMutation}
              loading={loadingRecesion}
            >
              Receder nómina
            </LoadingButton>
          </>
        )}
      >
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <Box sx={{ bgcolor: 'background.light', borderRadius: 3 }}>
              <Stack direction="column" spacing={0.7} sx={{ width: 300, margin: 1 }}>
                <Typography>
                  <b>Cesionario</b>
                </Typography>
                <Typography>
                  <b>{roster.cessionFund.masterEntity.name}</b>
                </Typography>
                <Typography>
                  {formatRut(roster.cessionFund.masterEntity.rut)}
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack
                direction="column"
                spacing={0.7}
                sx={{ alignItems: 'center' }}
              >
                <ArrowForwardIcon
                  color="primary"
                  fontSize="large"
                />
                <Typography>
                  <b>Documentos:</b> {roster.invoicesCount}
                </Typography>
                <Typography>
                  <b>Monto total:</b>
                </Typography>
                <Typography>
                  {roster.invoicesAmount.chileFormat}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ bgcolor: 'background.light', borderRadius: 3 }}>
              <Stack direction="column" spacing={0.7} sx={{ width: 300, margin: 1 }}>
                <Typography>
                  <b>Fondo</b>
                </Typography>
                <Typography>
                  <b>{roster.fund.masterEntity.name}</b>
                </Typography>
                <Typography>
                  {formatRut(roster.fund.masterEntity.rut)}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
      </FingoDialog>
    </>
  );
};

ConfirmRecesionDialog.propTypes = {
  roster: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invoicesAmount: MoneyType.isRequired,
    invoicesCount: PropTypes.number.isRequired,
    loanedInvoicesCount: PropTypes.number.isRequired,
    recesionDate: PropTypes.string,
    status: PropTypes.string.isRequired,
    cessionFund: PropTypes.shape({
      id: PropTypes.string.isRequired,
      masterEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        rut: PropTypes.string.isRequired,
      }),
    }),
    fund: PropTypes.shape({
      id: PropTypes.string.isRequired,
      masterEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        rut: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default ConfirmRecesionDialog;
