import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { openUrlAndDownload } from '@fingo/lib/helpers';

const CertificateDownloadCell = ({ cessionCertificate }) => {
  const openCertificate = useCallback(() => {
    openUrlAndDownload(cessionCertificate?.url, '');
  }, [cessionCertificate]);
  return (
    <Stack width="100%" direction="column" alignItems="center">
      <IconButton
        color="primary"
        disabled={!cessionCertificate}
        onClick={openCertificate}
      >
        <AttachFileIcon />
      </IconButton>
    </Stack>
  );
};

CertificateDownloadCell.propTypes = {
  cessionCertificate: PropTypes.string.isRequired,
};

export default CertificateDownloadCell;
