import React from 'react';
import moment from 'moment';
import { CompanyCell, MoneyCell } from '@fingo/lib/components/cells';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import WatchLaterRounded from '@mui/icons-material/WatchLaterRounded';
import { AbstratStatus } from '@fingo/lib/components/status';
import ConfirmRecesionDialog from '../components/funds-download/ConfirmRecesionDialog';
import CertificateDownloadCell from '../components/funds-download/CertificateDownloadCell';
import PDFDownloadCell from '../components/funds-download/PDFDownloadCell';

const rosterStatusToSpanish = {
  CREATED: { label: 'Creada', color: '#344A96' },
  PROCESSING: { label: 'Procesando recesion', color: '#BA8D00' },
  RECEDED: { label: 'Recedida', color: '#196400' },
  COMPLETED: { label: 'Recesión completa', color: '#196400' },
  PAID: { label: 'Giro recibido', color: '#196400' },
};

const cessionsDetails = [
  {
    key: 'completed',
    tooltip: 'Cesiones Completadas',
    labelName: 'completedCessions',
    color: 'success',
    Icon: CheckCircle,
  },
  {
    key: 'failed',
    tooltip: 'Cesiones Fallidas',
    labelName: 'failedCessions',
    color: 'error',
    Icon: Cancel,
  },
  {
    key: 'pending',
    tooltip: 'Cesiones Pendientes',
    labelName: 'pendingCessions',
    color: 'warning',
    Icon: WatchLaterRounded,
  },
];

const fundsDownloadColumns = [
  {
    field: 'pk',
    type: 'string',
    headerName: 'ID',
    renderCell: ({ row }) => (
      <Typography variant="body2">{row.id}</Typography>
    ),
    sortable: false,
    width: 50,
  },
  {
    field: 'fund',
    type: 'string',
    headerName: 'Fondo',
    renderCell: ({ row }) => (
      <CompanyCell company={row.fund.masterEntity} showBlacklist />
    ),
    sortable: false,
    flex: 2,
  },
  {
    field: 'cessionFund',
    type: 'string',
    headerName: 'Cesionario',
    renderCell: ({ row }) => (
      <CompanyCell company={row.cessionFund.masterEntity} showBlacklist />
    ),
    sortable: false,
    flex: 2,
  },
  {
    field: 'invoiceCount',
    type: 'string',
    headerName: 'Cantidad de Facturas',
    renderCell: ({ row }) => (
      <AbstratStatus
        row={row}
        statusArray={cessionsDetails}
        title={`${row.invoicesCount} facturas`}
      />
    ),
    sortable: false,
    flex: 1.9,
    align: 'center',
  },
  {
    field: 'totalAmount',
    type: 'string',
    headerName: 'Monto Total',
    align: 'center',
    renderCell: ({ row }) => <MoneyCell amount={row.invoicesAmount} />,
    sortable: false,
    flex: 1.5,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Estado',
    renderCell: ({ row }) => (
      <Stack spacing={0.7} width="100%" alignItems="center" justifyContent="center">
        <Chip
          label={rosterStatusToSpanish[row.status].label}
          variant="outlined"
          sx={{ color: rosterStatusToSpanish[row.status].color }}
        />
        {row.recesionDate && (
          <Typography variant="caption" style={{ marginLeft: 10 }}>
            Recedida el {moment(row.recesionDate).format('DD-MM-YYYY')}
          </Typography>
        )}
      </Stack>
    ),
    sortable: false,
    flex: 2,
  },
  {
    field: 'details',
    type: 'string',
    headerName: 'Nómina',
    align: 'center',
    renderCell: ({ row }) => (
      <MuiLink
        variant="body2"
        underline="hover"
        component={Link}
        to={(location) => `${location.pathname}/${row.id}`}
      >
        Detalle
      </MuiLink>
    ),
    sortable: false,
    flex: 1.5,
  },
  {
    field: 'receed',
    type: 'string',
    headerName: 'Receder',
    renderCell: ({ row }) => (
      <ConfirmRecesionDialog roster={row} />
    ),
    sortable: false,
    flex: 1.5,
  },
  {
    field: 'certificate',
    type: 'string',
    headerName: 'Certificado',
    renderCell: ({ row }) => (
      <CertificateDownloadCell cessionCertificate={row.cessionCertificate} />
    ),
    sortable: true,
    maxWidth: 100,
  },
  {
    field: 'pdf',
    type: 'string',
    headerName: 'Adjunto PDF',
    renderCell: ({ row }) => (
      <PDFDownloadCell id={row.id} />
    ),
    sortable: true,
    maxWidth: 100,
  },
];

export default fundsDownloadColumns;
