import React, { useState, useCallback } from 'react';
import {
  Button,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { GET_LOAN_MANAGERS, ADD_INVOICE_TO_ROSTER } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import { LoadingButton } from '@mui/lab';

const AddNewInvoice = ({ rosterId, disableButton }) => {
  const { addAlert } = useSnackBars();
  const [documentId, setDocumentId] = useState(null);
  const [rate, setRate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [retentionRate, setRetentionRate] = useState(null);
  const [open, toggleOpen] = useBooleanState();
  const minDate = moment().add(1, 'days');
  const handleToggleOpen = useCallback(() => {
    toggleOpen();
    setRate(null);
    setExpirationDate(null);
    setRetentionRate(null);
  }, []);
  const [addInvoiceToRoster, { loading }] = useMutation(ADD_INVOICE_TO_ROSTER, {
    variables: {
      documentId,
      rate,
      retentionRate,
      expirationDate: expirationDate?.format('YYYY-MM-DD'),
      rosterId,
    },
    refetchQueries: [
      { query: GET_LOAN_MANAGERS, variables: { invoiceLoanRosterId: parseInt(rosterId ?? 0, 10) } },
    ],
    onCompleted: handleToggleOpen,
    onError: (err) => addAlert({
      id: 'recesion-error',
      severity: 'error',
      message: `Error al agregar la factura: ${err}`,
    }),
  });
  return (
    <>
      <Tooltip title={disableButton ? 'La nómina ya presenta una recesión' : ''}>
        <span>
          <Button
            variant="contained"
            size="small"
            onClick={toggleOpen}
            disabled={disableButton}
          >
            Agregar Factura
          </Button>
        </span>
      </Tooltip>
      <FingoDialog
        open={open}
        handleClose={handleToggleOpen}
        title="Nuevo Documento a la Nómina"
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={addInvoiceToRoster}
            loading={loading}
            disabled={!documentId || !rate || !retentionRate || !expirationDate}
          >
            Agregar
          </LoadingButton>
      )}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Stack direction="column">
              <Typography>
                Ingresa el ID de la factura:
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => setDocumentId(e.target.value)}
              />
            </Stack>
            <Stack direction="column">
              <Typography>
                Ingresa la tasa:
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => setRate(e.target.value)}
              />
            </Stack>
            <Stack direction="column">
              <Typography>
                Ingresa la tasa de Retención:
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => setRetentionRate(e.target.value)}
              />
            </Stack>
            <Stack direction="column" spacing={0.5}>
              <Typography>
                Ingresa la fecha de expiración:
              </Typography>
              <FingoDatePicker
                autoOk
                allowKeyboardControl={false}
                disableToolbar
                variant="inline"
                margin="none"
                inputVariant="outlined"
                minDate={minDate}
                value={expirationDate || null}
                onChange={(date) => setExpirationDate(date)}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </FingoDialog>
    </>
  );
};

AddNewInvoice.propTypes = {
  rosterId: PropTypes.string,
  disableButton: PropTypes.bool.isRequired,
};

AddNewInvoice.defaultProps = {
  rosterId: null,
};

export default AddNewInvoice;
