import React from 'react';
import { FadeSwitch } from '@fingo/lib/components/switches';
import { Route, useRouteMatch } from 'react-router-dom';
import FundsDownload from './funds-download/FundsDownload';
import RosterDetail from './roster-details/RosterDetail';

const FundsSwitch = () => {
  const routePath = useRouteMatch();
  return (
    <FadeSwitch depth={5}>
      <Route id="profile" key="profile" path={`${routePath.path}/:rosterId`}>
        <RosterDetail />
      </Route>
      <Route id="index" key="index" path={`${routePath.path}`}>
        <FundsDownload />
      </Route>
    </FadeSwitch>
  );
};

export default FundsSwitch;
