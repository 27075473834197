import React from 'react';
import PropTypes from 'prop-types';
import AddNewInvoice from './AddNewInvoiceDialog';
import DeleteInvoices from './DeleteInvoicesDialog';

const RosterDetailActions = ({ selectedDocuments, setSelectedDocuments, data }) => {
  const invoiceLoanRoster = data[0]?.invoiceLoanRoster;
  return (
    <>
      <DeleteInvoices
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
      />
      <AddNewInvoice
        rosterId={invoiceLoanRoster?.id}
        disableButton={invoiceLoanRoster?.recesionDate !== null}
      />
    </>
  );
};

RosterDetailActions.propTypes = {
  setSelectedDocuments: PropTypes.func.isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceLoanRoster: PropTypes.shape({
        id: PropTypes.string.isRequired,
        recesionDate: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default RosterDetailActions;
