import React from 'react';
import {
  Button,
  DialogContent,
  Typography,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { GET_LOAN_MANAGERS, DELETE_INVOICES_FROM_ROSTER } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

const DeleteInvoices = ({ selectedDocuments, setSelectedDocuments }) => {
  const { addAlert } = useSnackBars();
  const [open, toggleOpen] = useBooleanState();
  const [deleteInvoices, { loading }] = useMutation(DELETE_INVOICES_FROM_ROSTER, {
    variables: {
      documentIds: selectedDocuments,
    },
    refetchQueries: [
      GET_LOAN_MANAGERS,
    ],
    onCompleted: () => {
      setSelectedDocuments([]);
      toggleOpen();
    },
    onError: (err) => addAlert({
      id: 'recesion-error',
      severity: 'error',
      message: `Error al eliminar la factura: ${err}`,
    }),
  });
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={toggleOpen}
        disabled={!selectedDocuments.length}
      >
        Eliminar Facturas
      </Button>
      <FingoDialog
        open={open}
        handleClose={toggleOpen}
        title="Nuevo Documento a la Nómina"
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={deleteInvoices}
            loading={loading}
          >
            Confirmar
          </LoadingButton>
      )}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography>
              ¿Estas seguro que quieres eliminar las facturas de la nómina?
            </Typography>
          </Stack>
        </DialogContent>
      </FingoDialog>
    </>
  );
};

DeleteInvoices.propTypes = {
  setSelectedDocuments: PropTypes.func.isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeleteInvoices;
