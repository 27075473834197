import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { CompanyCell } from '@fingo/lib/components/cells';
import { formatMoney } from '@fingo/lib/helpers';
import CertificateDownloadCell from '../components/roster-details/CertificateDownloadCell';
import PDFDownloadCell from '../components/roster-details/PDFDownloadCell';

const statusLabel = {
  PROCESSING: { label: 'Procesando', color: '#344A96' },
  CEDED: { label: 'Cedida', color: '#344A96' },
  FUNDCESSIONFAILED: { label: 'Fallida Cesión', color: '#F44336' },
  RECEIVEDCESSIONFAILED: { label: 'Fallida Cesión Recibida', color: '#F44336' },
  OVERDUE: { label: 'Expirada', color: '#E69138' },
  FINISHED: { label: 'Completada', color: '#196400' },
};

const rosterDetailColumns = [
  {
    field: 'document_Folio',
    type: 'string',
    headerName: 'Folio',
    align: 'center',
    renderCell: ({ row }) => <Typography>{row.document.folio}</Typography>,
    sortable: true,
    flex: 1.5,
  },
  {
    field: 'document_Company_MasterEntity_Name',
    type: 'string',
    headerName: 'Compañía',
    renderCell: ({ row }) => (
      <CompanyCell company={row.document.company.masterEntity} showBlacklist />
    ),
    sortable: true,
    flex: 2,
  },
  {
    field: 'document_Receiver_Name',
    type: 'string',
    headerName: 'Deudor',
    renderCell: ({ row }) => (
      <CompanyCell company={row.document.receiver} showBlacklist />
    ),
    sortable: true,
    flex: 2,
  },
  {
    field: 'dates',
    type: 'string',
    headerName: 'Fechas',
    renderCell: ({ row }) => (
      <Grid width="100%" alignItems="flex-start" justifyContent="center">
        <Typography variant="body2" width="inherit" noWrap>
          Emisión: {row.document.dateIssued.format('YYYY-MM-DD')}
        </Typography>
        <Typography variant="caption" width="inherit" noWrap>
          Pago: {row.document.dateToPay.format('YYYY-MM-DD')}<br />
        </Typography>
        <Typography variant="caption" width="inherit" noWrap>
          E. Fondo: {row.expirationDate}
        </Typography>
      </Grid>
    ),
    sortable: false,
    flex: 2,
  },
  {
    field: 'financedAmount',
    type: 'string',
    headerName: 'Monto Factura',
    renderCell: ({ row }) => (
      <Grid width="100%" alignItems="flex-start" justifyContent="center">
        <Typography variant="body2" width="inherit" noWrap>
          ${formatMoney(row.document.amountWithIva)}
        </Typography>
        <Typography variant="caption" width="inherit" noWrap>
          Tasa: {row.monthlyRate}%
        </Typography>
      </Grid>
    ),
    sortable: false,
    flex: 2,
  },
  {
    field: 'assignmentStatus',
    type: 'string',
    headerName: 'Estado Cesión',
    renderCell: ({ row }) => {
      const status = statusLabel[row.status];
      return <Chip label={status.label} variant="outlined" sx={{ color: status.color }} />;
    },
    sortable: false,
    flex: 2,
  },
  {
    field: 'certificate',
    type: 'string',
    headerName: 'Certificado',
    renderCell: ({ row }) => (
      <CertificateDownloadCell assignment={row.assignment} />
    ),
    sortable: false,
    maxWidth: 90,
  },
  {
    field: 'pdf',
    type: 'string',
    headerName: 'PDF',
    renderCell: ({ row }) => (
      <PDFDownloadCell documents={row.document.documents} />
    ),
    sortable: false,
    maxWidth: 50,
  },
];

export default rosterDetailColumns;
